import React from 'react';
import {Card, Col, Content, Layout, Link, Paragraph, Row, Space, Title} from "./layout/Content";

import {Footer, Header, Navigation} from "./partial/Navigation";

const LandingPage: React.FC = () => {
    return (
        <Layout>
            <Header />
            <main className="app-main">
                <Content>
                    <Row>

                        <Col xs={12}>

                            <Space direction="vertical" justify="center">
                                <h2>Articles by OakFrame</h2>
                                <Paragraph>
                                    At <strong>OakFrame</strong>, we transform ideas into reality through
                                    cutting-edge technology. Our team is dedicated to delivering high-quality,
                                    scalable solutions tailored to your unique needs.
                                </Paragraph>

                                <Row>
                                    <Col xs={12} md={6}>
                                        <Card Full>
                                            <Space direction={"vertical"} justify={"space-between"} Full>
                                                <Space direction="vertical">
                                                    <h3>Innovations in Web 3.0</h3>
                                                    <Paragraph>
                                                        Discover the latest trends and breakthroughs in Web 3.0 technology. From decentralized applications to blockchain integration, explore how Web 3.0 is transforming the internet and paving the way for a more secure and user-centric web experience.
                                                    </Paragraph>
                                                </Space>
                                                <Space>
                                                    <a href="/articles/innovations-in-web-3" className="hero-link">Read More</a>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Card Full>
                                            <Space direction={"vertical"} justify={"space-between"} Full>
                                                <Space direction="vertical">
                                                    <h3>Key Changes in Web Architecture</h3>
                                                    <Paragraph>
                                                        Examine the critical shifts in web architecture over the past decade. Learn about the evolution of monolithic to microservices architecture, the rise of serverless computing, and the benefits these changes bring to modern web development.
                                                    </Paragraph>
                                                </Space>
                                                <Space>
                                                    <a href="/articles/key-changes-web-architecture" className="hero-link">Read More</a>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Card Full>
                                            <Space direction={"vertical"} justify={"space-between"} Full>
                                                <Space direction="vertical">
                                                    <h3>Aging up Devices</h3>
                                                    <Paragraph>
                                                        Discover the techniques and technologies used to extend the lifespan of aging devices. From software updates to hardware modifications, explore how to keep your devices running efficiently and securely.
                                                    </Paragraph>
                                                </Space>
                                                <Space>
                                                    <a href="/articles/aging-up-devices" className="hero-link">Read More</a>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Card Full>
                                            <Space direction={"vertical"} justify={"space-between"} Full>
                                                <Space direction="vertical">
                                                    <h3>Distributed Systems Design</h3>
                                                    <Paragraph>
                                                        Uncover the principles of designing distributed systems. Learn about the challenges and best practices in building scalable, resilient, and efficient distributed architectures.
                                                    </Paragraph>
                                                </Space>
                                                <Space>
                                                    <a href="/articles/distributed-systems-design" className="hero-link">Read More</a>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Card Full>
                                            <Space direction={"vertical"} justify={"space-between"} Full>
                                                <Space direction="vertical">
                                                    <h3>Order & Chaos in Systems</h3>
                                                    <Paragraph>
                                                        Dive into the fascinating dynamics of order and chaos in system design. Explore how to balance stability and flexibility to create robust systems that can adapt to changing requirements and unexpected challenges.
                                                    </Paragraph>
                                                </Space>
                                                <Space>
                                                    <a href="/articles/order-chaos-systems" className="hero-link">Read More</a>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>
                                </Row>



                                <Row className={"hero alt"}>
                                    <Col xs={12}>
                                        <Content>

                                            <h3>Benefits of Our Approach</h3>
                                            <ul>
                                                <li>Scalable and Robust Solutions</li>
                                                <li>High-Performance Embedded Systems</li>
                                                <li>Continuous Improvement and Adaptability</li>
                                                <li>Seamless Integration and High Availability</li>
                                            </ul>

                                        </Content>
                                    </Col>
                                </Row>
                            </Space>

                        </Col>

                    </Row>

                    <Row>
                        <Col xs={12} md={6}>
                            <section id="our-expertise">
                                <h2>Our Expertise</h2>
                                <p>
                                    We are pioneers in <em>Microservice architecture</em>, delivering scalable and
                                    robust solutions tailored to meet the unique needs of our clients. Our team excels
                                    in research, design, and development, providing comprehensive services that cover:
                                </p>
                                <ul>
                                    <li>Web Development</li>
                                    <li>Hardware Integration</li>
                                    <li>Frontend Engineering</li>
                                    <li>Backend Development</li>
                                    <li>Cloud Computing</li>
                                    <li>Video Game Development</li>
                                    <li>Interactive Installations</li>
                                </ul>
                            </section>
                        </Col>
                        <Col xs={12} md={6}>
                            <section id="our-services">
                                <h2>Our Services</h2>
                                <p>
                                    Our service offerings include
                                    both <strong>free</strong> and <strong>premium</strong> APIs accessible through our
                                    website, enabling seamless integration with
                                    your systems, coming soon!
                                </p>
                                <ul>
                                    <li><strong>AI & Large Language Models</strong></li>
                                    <li>BLIP Image Description</li>
                                    <li>OCR with Enhanced Accuracy</li>
                                    <li><strong>Data Warehouse & Management</strong></li>
                                    <li>Cloud Logging & Observability</li>
                                </ul>
                                <p>
                                    We are dedicated to pushing the boundaries of technology and delivering exceptional
                                    results that drive success for our clients.
                                </p>
                            </section>
                        </Col>

                        <Col xs={12} className={"hero altb"}>
                            <Content>
                                <section id="location">
                                    <h2>Located in Greater Dallas</h2>
                                    <p>Based in Dallas, Texas, <strong>OakFrame Interactive Ltd</strong>. is
                                        strategically
                                        positioned to
                                        serve clients both locally and globally. Our central location allows us to
                                        collaborate closely with our clients and provide timely, efficient services.</p>
                                </section>
                            </Content>
                        </Col>
                    </Row>


                    {/*<Row>
                        <Col xs={12}>
                            <Space direction="vertical">
                                <h2>OakFrame Product Family</h2>

                                <Row>
                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction="vertical">
                                                <h3>API Endpoints & Services, Enterprise Consulting</h3>
                                                <Paragraph>
                                                    Enhance your applications with our comprehensive API services,
                                                    providing robust and scalable solutions for your data and
                                                    functionality needs.
                                                </Paragraph>
                                                <a href="#api-endpoints" className="hero-link">oakframe.org</a>
                                            </Space>
                                        </Card>
                                    </Col>

                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction="vertical">
                                                <h3>KeyPiece Interactive Programming and Storytelling Tool</h3>
                                                <Paragraph>
                                                    Empower your creativity with KeyPiece, our innovative tool designed
                                                    for interactive programming and storytelling, perfect for both
                                                    beginners and professionals.
                                                </Paragraph>
                                                <a href="#keypiece" className="hero-link">keypiece.org</a>
                                            </Space>
                                        </Card>
                                    </Col>

                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction="vertical">
                                                <h3>Peek.blog</h3>
                                                <Paragraph>
                                                    Join Peek.blog, our vibrant online community for photo and story
                                                    enthusiasts. Share your experiences, connect with others, and
                                                    explore a world of creativity.
                                                </Paragraph>
                                                <a href="#peek-blog" className="hero-link">peek.blog</a>
                                            </Space>
                                        </Card>
                                    </Col>


                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction={"vertical"} justify={"space-between"} full>
                                                <Space direction="vertical">
                                                    <h3>ScrapsJS</h3>
                                                    <Paragraph>
                                                        ScrapsJS is an open-source inline JavaScript editing and running
                                                        library/framework, used by KeyPiece and our OakFrame internal
                                                        documentation pages.
                                                    </Paragraph>
                                                </Space>
                                                <Space>
                                                    <a href="#scrapsjs" className="hero-link">Learn More</a>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>

                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction={"vertical"} justify={"space-between"} full>

                                                <Space direction="vertical">
                                                    <h3>swuirm</h3>
                                                    <Paragraph>
                                                        swuirm is an open-source tool that enhances Docker, Docker
                                                        Compose,
                                                        Docker Swarm, and Terraform with SSH, build and deployment
                                                        configurations, environments, and more, all within a
                                                        user-friendly
                                                        UI.
                                                    </Paragraph>
                                                </Space>
                                                <Space>
                                                    <a href="#swuirm" className="hero-link">Discover More</a>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>

                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction={"vertical"} justify={"space-between"} full>

                                                <Space direction="vertical">
                                                    <h3>DataBox</h3>
                                                    <Paragraph>
                                                        DataBox is an open-source Electron TSX desktop application for
                                                        data
                                                        engineers. It includes tools for image and video manipulation,
                                                        data
                                                        tagging and logging, audio-to-text conversion, AI LLM
                                                        integrations,
                                                        and cross-platform account and data integration.
                                                    </Paragraph>
                                                </Space>
                                                <Space> <a href="/products/#databox" className="hero-link">Find Out More</a>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>
                                </Row>
                            </Space>
                        </Col>


                    </Row>*/}
                </Content>
            </main>
            <Footer/>
        </Layout>

    );
}

export default LandingPage;
