import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, ScatterController, LinearScale, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { Scatter } from 'react-chartjs-2';

// Register components with Chart.js
ChartJS.register(ScatterController, LinearScale, PointElement, Title, Tooltip, Legend);

type ClusterData = {
    centroid: number[];
    cluster: number[][];
    clusterInd: number[];
};

const ClustersChart: React.FC = () => {
    const [data, setData] = useState<ClusterData[]>([]);

    useEffect(() => {
        fetch('http://localhost:8000/clusters')
            .then(response => response.json())
            .then(data => {
                if (Array.isArray(data)) {
                    setData(data);
                } else {
                    console.error('Unexpected data format', data);
                }
            })
            .catch(err => console.error(err));
    }, []);

    if (!data.length) return <div>Loading...</div>;

    const chartData = {
        datasets: data.map((clusterData, index) => ({
            label: `Cluster ${index + 1}`,
            data: clusterData.cluster.map(point => ({ x: point[0], y: point[1] })),
            backgroundColor: `rgba(75, 192, 192, 0.6)`,
            borderColor: `rgba(75, 192, 192, 1)`,
            pointRadius: 5,
        })),
    };

    const options = {
        scales: {
            x: {
                type: 'linear' as const,
                position: 'bottom' as const,
            },
            y: {
                type: 'linear' as const,
            },
        },
        plugins: {
            legend: {
                position: 'top' as const, // Explicitly specifying as const
            },
            title: {
                display: true,
                text: 'K-Means Clusters',
            },
        },
    };

    return (
        <div>
            <h2>Clusters Visualization</h2>
            <Scatter data={chartData} options={options} />
        </div>
    );
};

export default ClustersChart;
