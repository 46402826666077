import {Button, Card, Space, Title} from "../layout/Content";
import {Modal} from "../layout/Defaults";
import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import {SignIn} from "./SignIn";


export const LoginModal = ({children, onClose, defaultMode = true}: { children: React.ReactNode, onClose?: any, defaultMode?:boolean }) => {
    const [modal, setModal] = useState<boolean>(false);
    return (
        <div onClick={()=>{
            if (!modal){
                setModal(true);
            }
        }}>
            {children}
            <Modal visible={modal} title={""}
                   content={<SignIn defaultMode={defaultMode}/>} onClose={() => {
                console.log('Modal closed');
                setModal(false);
                if (onClose){onClose();}
            }}/>
        </div>
    )
}

