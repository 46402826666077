import React, { useState, useEffect } from 'react';
import {Button} from "../layout/Content";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {faHeart as faHeartRegular} from "@fortawesome/free-regular-svg-icons";
import {useUserProfileContext} from "../../lib/UserContext";

interface LikeButtonProps {
    uuid: string;
}

interface Stats {
    total_likes: number;
    current_user_likes: boolean;
}

const LikeButton: React.FC<LikeButtonProps> = ({ uuid }) => {
    const [stats, setStats] = useState<Stats>({ total_likes: 0, current_user_likes: false });
    const [loading, setLoading] = useState<boolean>(true);
    const { queryUserProfile, getUserProfile, hasQueriedUserProfile } = useUserProfileContext();


    const fetchStats = async () => {
        try {
            const response = await fetch(`//${process.env.REACT_APP_DOMAIN_API}/post/stats/${uuid}`, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const data = await response.json();
                setStats(data);
            } else {
                console.error('Failed to fetch stats');
            }
        } catch (error) {
            console.error('Error fetching stats:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleLike = async () => {
        try {
            const response = await fetch(`//${process.env.REACT_APP_DOMAIN_API}/post/${stats.current_user_likes ? 'unlike' : 'like'}/${uuid}`, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                setStats(prevStats => ({
                    total_likes: prevStats.current_user_likes ? prevStats.total_likes - 1 : prevStats.total_likes + 1,
                    current_user_likes: !prevStats.current_user_likes
                }));
            } else {
                console.error('Failed to toggle like');
            }
        } catch (error) {
            console.error('Error toggling like:', error);
        }
    };

    useEffect(() => {
        fetchStats();
    }, [uuid]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
            <Button onClick={handleLike} type={getUserProfile()?(stats.current_user_likes?"like":"default"):"disabled"}>
               <span><FontAwesomeIcon icon={stats.current_user_likes ? faHeart : faHeartRegular} /></span>
            </Button>
    );
};

export default LikeButton;
