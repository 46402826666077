import React, { createContext, useContext, useEffect, useState } from 'react';

interface UserDetailOption {
    value:string;
    public:boolean;
}

type UserDetailOptionType = Record<string, UserDetailOption>;

export interface UserAttribute {
    displayName: string;
    username: string;
    userDetails?:{
        gender?:UserDetailOption; // mars|venus|mars-and-venus|non-binary|genderless
        birthday?:UserDetailOption;
    }
}

export interface ProvidedAttribute {
    provider?: string; // e.g., 'google', 'facebook'
    region?: string;
    profilePictureUrl?: string; // Often provided by social logins
    firstName?: string;
    lastName?: string;
    locale?: string;
    email?: string; // Assuming email can be edited, depending on your use case
}

export interface UserProfile {
    googleId?: string;
    uuid: string;
    attributes: UserAttribute;
    provided: ProvidedAttribute;
}

type UserProfileContextType = {
    userProfile: UserProfile | null;
    setUserProfile: (userProfile: UserProfile | null) => void;
    getUserProfile: () => UserProfile | null;
    queryUserProfile: () => Promise<void>;
    hasQueriedUserProfile: () => boolean;
};

const UserProfileContext = createContext<UserProfileContextType>({
    userProfile: null,
    setUserProfile: () => {},
    getUserProfile: () => null,
    queryUserProfile: async () => {},
    hasQueriedUserProfile: () => false,
});

export const useUserProfileContext = () => {
    return useContext(UserProfileContext);
};

export const UserProfileProvider: React.FC<any> = ({ children }) => {
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
    const [hasQueried, setHasQueried] = useState<boolean>(false);

    const getUserProfile = () => userProfile;

    const queryUserProfile = async () => {
        try {
            const response = await fetch(`//${process.env.REACT_APP_DOMAIN_API}/profile`, {
                method: 'GET',
                credentials: 'include', // This tells the browser to include cookies with the request
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();
                setUserProfile(data);
            } else {
                console.log('User not signed in');
            }
        } catch (error) {
            console.error('Error fetching user profile:', error);
        } finally {
            setHasQueried(true);
        }

    };

    const hasQueriedUserProfile = () => hasQueried;

    return (
        <UserProfileContext.Provider
            value={{
                userProfile,
                setUserProfile,
                getUserProfile,
                queryUserProfile,
                hasQueriedUserProfile,
            }}
        >
            {children}
        </UserProfileContext.Provider>
    );
};

export default UserProfileProvider;
