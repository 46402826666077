import React from 'react';
//import 'oakd/src/Reset.scss';
import './App.scss';
import LandingPage from "./component/Landing";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PageComponent} from "./component/PageComponent";
import Articles from "./component/Articles";
import Reader from "./component/Reader";
import ClustersChart from "./component/ClustersChart";
import {StyleGuide} from "./component/page/StyleGuide";
import {getPlatform, getWrapper} from "./lib/Wrapper";

function App() {
  return (
      <div className={["App", getPlatform(), getWrapper()].join(" ")}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<PageComponent element={<LandingPage/>}/>}/>
                <Route path="/style" element={<PageComponent element={<StyleGuide/>}/>}/>
                <Route path="/reader" element={<PageComponent element={<Reader/>}/>}/>
                <Route path="/articles" element={<PageComponent element={<Articles/>}/>}/>
                <Route path="/clusters" element={<PageComponent element={<ClustersChart/>}/>}/>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
