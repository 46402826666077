import React from 'react';
import {Footer, Header, Navigation} from "./partial/Navigation";
import PageTurner from "./partial/PageTurner";
import {Col, Content, Layout, Row} from "./layout/Content";

const Reader: React.FC = () => {
    return (
        <Layout>
            <Header />
            <main className="app-main">
                <Content>
                    <Row style={{height:"100%"}}>

                        <Col xs={12}>

                            <PageTurner>
                                <div>Page 1</div>
                                <div>Page 2</div>
                                <div>Page 3</div>
                                {/* Add more pages as needed */}
                            </PageTurner>

                        </Col>

                    </Row>


                </Content>
            </main>
            <Footer/>
        </Layout>

    );
}

export default Reader;
