import React, {ChangeEvent, useState} from 'react';
import {Space} from "../layout/Content";
import {formatBytes, uploadChunk, UploadProgress} from "../../lib/FileUploadWithProgress";

interface UploadFileProps {}

const resetProgress = (): UploadProgress => ({
    sentBytes: 0,
    totalBytes: 0,
    bytesPerSecond: 0,
    progress: 0,
    timeRemaining: 0,
});


const UploadFile: React.FC<UploadFileProps> = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [progress, setProgress] = useState<UploadProgress>(resetProgress());
    const [accumulatedProgress, setAccumulatedProgress] = useState<UploadProgress>(resetProgress());


    const aProgress = (progressUpdate:UploadProgress)=>{
        setProgress(progressUpdate);
    }


    const uploadFile = async (file: File) => {
        setLoading(true);
        setAccumulatedProgress({
            bytesPerSecond: 0, progress: 0, sentBytes: 0, timeRemaining: 0, totalBytes: file.size
        });

        const chunkSize = 60 * 1024 * 1024; // 60MB for simplicity
        const totalChunks = Math.ceil(file.size / chunkSize);
        const fileId = `${file.name}`; // Unique ID for the file assembly on the server

        console.log(`Total chunks calculated: ${totalChunks}, file size: ${file.size} bytes`);

        for (let i = 0; i < totalChunks; i++) {
            setProgress(resetProgress())

            const chunk = file.slice(i * chunkSize, (i + 1) * chunkSize);
            const formData = new FormData();
            formData.append('fileChunk', chunk);
            formData.append('chunkIndex', i.toString());
            formData.append('totalChunks', totalChunks.toString());
            formData.append('fileId', fileId);

            // Retry logic
            let retryCount = 0;
            const maxRetries = 3;
            while (retryCount < maxRetries) {
                try {
                    await uploadChunk(formData, aProgress);
                    break; // Break the loop if upload is successful
                } catch (error) {
                    console.error(`Retry ${retryCount + 1} for chunk ${i + 1}`);
                    retryCount++;
                    if (retryCount === maxRetries) {
                        console.error(`Failed to upload chunk after ${maxRetries} attempts.`);
                    }
                }
            }

            setAccumulatedProgress({
                bytesPerSecond: accumulatedProgress.bytesPerSecond,
                progress: Math.round(((i) / totalChunks) * 100),
                sentBytes: (i + 1) * chunkSize,
                timeRemaining: 0,
                totalBytes: file.size
            });
        }

        setLoading(false);

    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            uploadFile(file);
        }
    };

    return (
        <div>
            <input type="file" onChange={handleFileChange} />
            {loading&&<Space>
                <progress max={100} value={Math.round(((accumulatedProgress.sentBytes+progress.sentBytes) / accumulatedProgress.totalBytes) * 100)}/>
                <span>{formatBytes(accumulatedProgress.sentBytes+progress.sentBytes)}/{formatBytes(accumulatedProgress.totalBytes)} ({formatBytes(progress.bytesPerSecond)}/s)</span>
                <span>Time remaining: {progress.timeRemaining} s</span>
            </Space>}
        </div>
    );
};

export default UploadFile;
