import {
    Col,
    Content,
    Layout,
    Page,
    Row,
    Paragraph,
    Space,
    Title,
    Divider,
    Button, Card, Input, Link, Pill
} from "./layout/Content";
import React from 'react';
import {Footer, Header, Navigation} from "./partial/Navigation";

const LandingPage: React.FC = () => {
    return (
        <Layout>
            <Header/>
            <Page Grow className="app-main">
                <Content className={"limits pad"}>
                    <Row Gap>
                        <Col xs={24} md={12} className="hero">
                                <Space direction="vertical" Full align={"center"} justify={"center"}>

                                        <Content Pad >
                                            <Space direction={"vertical"} Fill GapSm>
                                        <Title Large style={{color:"white"}}>The Future Awaits</Title>
                                        <p>Discover the potential of cutting-edge technology.</p>
                                        <Button type={"default"}>Learn More</Button>
                                            </Space>
                                        </Content>

                                </Space>


                        </Col>
                        <Col xs={24} md={12}>
                            <Space GapSm direction={"vertical"}>
                                <h2>About Us</h2>
                                <p>
                                    We at <strong>OakFrame Interactive Ltd.</strong> turn your infrastructural dreams into
                                    reality. Our core proficiencies and offerings include:
                                </p>
                                <ul>
                                    <li><strong>Software Architecture Design</strong></li>
                                    <li><strong>Migration & Change Management</strong></li>
                                    <li><strong>Private Cloud & On-Premisis</strong></li>
                                    <li><strong>Hardware-Level Coding</strong></li>
                                    <li><strong>Machine Learning & AI Compute</strong></li>
                                    <li><strong>Embedded Electronics</strong></li>
                                    <li>Web & App Development</li>
                                    <li>Backend Systems</li>
                                    <li>Cloud & Edge Solutions</li>
                                    <li>Video Game Development</li>
                                    <li>Interactive Installations</li>
                                </ul>
                                <p>
                                    We believe in bold steps, courageous leadership, and delivering high-quality
                                    results. Together, let's define your future.
                                </p>
                            </Space>
                        </Col>

                        <Col xs={24}>

                            <Space direction="vertical" justify="center" GapSm>
                                <h2>Innovative Solutions</h2>
                                <Paragraph>
                                    At <strong>OakFrame</strong>, we transform ideas into reality through
                                    cutting-edge technology. Our team is dedicated to delivering high-quality,
                                    scalable solutions tailored to your unique needs.
                                </Paragraph>
                            </Space>

                        </Col>

                        <Col xs={24} md={8}>
                            <Space justify="space-between" direction="vertical"  GapSm Full>
                                <Space direction="vertical" GapSm>
                                    <h3>Microservices</h3>
                                    <Paragraph>
                                        We excel in Microservice architecture, providing scalable and
                                        robust solutions that ensure seamless integration and high
                                        availability. Our approach guarantees that your systems are
                                        flexible, efficient, and future-proof.
                                    </Paragraph>
                                </Space>
                                <Space direction="vertical" justify="end">
                                    <Space GapSm><Pill>Scalable</Pill><Pill>Reliable</Pill></Space>
                                </Space>
                            </Space>
                        </Col>
                        <Col xs={24} md={8}>
                            <Space justify="space-between" direction="vertical" GapSm Full>
                                <Space direction="vertical" GapSm>
                                    <h3>Embedded Technologies</h3>
                                    <Paragraph>
                                        Our expertise in hardware-level coding and embedded devices allows
                                        us to create sophisticated, high-performance solutions for various
                                        applications. We ensure that all components work seamlessly and
                                        efficiently together.
                                    </Paragraph>
                                </Space>
                                <Space direction="vertical" justify="end">
                                    <Space GapSm><Pill>Extensive</Pill><Pill>Optimized</Pill></Space>
                                </Space>
                            </Space>
                        </Col>


                        <Col xs={24} md={8}>
                            <Space justify="space-between" direction="vertical" GapSm Full>
                                <Space direction="vertical" GapSm>
                                    <h3>Iterative Development</h3>
                                    <Paragraph>
                                        We believe in iterative development, enabling us to deliver
                                        continuous improvements and adapt quickly to changing requirements.
                                        This approach ensures that we always meet your business goals and
                                        exceed expectations, providing value at every step.
                                    </Paragraph>
                                </Space>
                                <Space direction="vertical" justify="end">
                                    <Space GapSm><Pill>Agile</Pill><Pill>Integrated</Pill></Space>
                                </Space>
                            </Space>
                        </Col>

                        <Col xs={24} className={"hero alt"}>
                            <Content Pad>
                                <Space GapSm id="our-benefits" direction={"vertical"}>
                                <h2>Benefits of Our Approach</h2>
                                <ul>
                                    <li>Scalable and Robust Solutions</li>
                                    <li>High-Performance Embedded Systems</li>
                                    <li>Continuous Improvement and Adaptability</li>
                                    <li>Seamless Integration and High Availability</li>
                                </ul>
                                </Space>
                            </Content>
                        </Col>


                        {/*<Col xs={24} md={24}>*/}
                        {/*    <Space GapSm id="our-expertise" direction={"vertical"}>*/}
                        {/*        <h2>Clients at all Scales, Startup, Small-medium Business, Fortune 500, State Municipality, Government at a Federal Level</h2>*/}
                        {/*        <Space direction={"horizontal"} justify={"space-around"} align={"center"} Gap style={{padding:"20pt 0"}} Wrap>*/}
                        {/*            <img src={"/asset/landing/logo-dfb.png"} className={"client-logo"}/>*/}
                        {/*            <img src={"/asset/landing/logo-stw.png"} className={"client-logo"}/>*/}
                        {/*            <img src={"/asset/landing/logo-fcc.png"} className={"client-logo"}/>*/}
                        {/*            <img src={"/asset/landing/logo-freddie.png"} className={"client-logo"}/>*/}
                        {/*            <img src={"/asset/landing/logo-plano-sm.png"} className={"client-logo"}/>*/}
                        {/*        </Space>*/}
                        {/*    </Space>*/}
                        {/*</Col>*/}

                        <Col xs={24} md={12}>
                            <Space GapSm id="our-expertise">
                                <h2>Our Expertise</h2>
                                <p>
                                    We are pioneers in <em>Microservice Architecture</em>, delivering scalable and
                                    robust solutions tailored to meet the unique needs of our clients. Our team excels
                                    in research, design, and development, providing comprehensive services that cover:
                                </p>
                                <ul>
                                    <li>Web Development</li>
                                    <li>Hardware Integration</li>
                                    <li>Frontend Engineering</li>
                                    <li>Backend Development</li>
                                    <li>Cloud Computing</li>
                                    <li>Video Game Development</li>
                                    <li>Interactive Installations</li>
                                </ul>
                            </Space>
                        </Col>


                        <Col xs={24} md={12}>
                            <Space GapSm id="our-services">
                                <h2>Our Services</h2>
                                <p>
                                    Our service offerings include
                                    both <strong>free</strong> and <strong>premium</strong> APIs accessible through our
                                    website, enabling seamless integration with
                                    your systems, coming soon!
                                </p>
                                <ul>
                                    <li><strong>AI & Large Language Models</strong></li>
                                    <li>BLIP Image Description</li>
                                    <li>OCR with Enhanced Accuracy</li>
                                    <li><strong>Data Warehouse & Management</strong></li>
                                    <li>Cloud Logging & Observability</li>
                                </ul>
                                <p>
                                    We are dedicated to pushing the boundaries of technology and delivering exceptional
                                    results that drive success for our clients.
                                </p>
                            </Space>
                        </Col>
                        <Col xs={24} className={"hero altb"}>
                            <Content Pad>
                                <Space id="location" direction={"vertical"} GapSm>
                                    <h2>Located in Greater Dallas</h2>
                                    <p>Based in Dallas, Texas, <strong>OakFrame Interactive Ltd</strong>. is
                                        strategically
                                        positioned to
                                        serve clients both locally and globally. Our central location allows us to
                                        collaborate closely with our clients and provide timely, efficient services.</p>
                                </Space>
                            </Content>
                        </Col>
                    </Row>


                    {/*<Row>
                        <Col xs={12}>
                            <Space direction="vertical">
                                <h2>OakFrame Product Family</h2>

                                <Row>
                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction="vertical">
                                                <h3>API Endpoints & Services, Enterprise Consulting</h3>
                                                <Paragraph>
                                                    Enhance your applications with our comprehensive API services,
                                                    providing robust and scalable solutions for your data and
                                                    functionality needs.
                                                </Paragraph>
                                                <a href="#api-endpoints" className="hero-link">oakframe.org</a>
                                            </Space>
                                        </Card>
                                    </Col>

                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction="vertical">
                                                <h3>KeyPiece Interactive Programming and Storytelling Tool</h3>
                                                <Paragraph>
                                                    Empower your creativity with KeyPiece, our innovative tool designed
                                                    for interactive programming and storytelling, perfect for both
                                                    beginners and professionals.
                                                </Paragraph>
                                                <a href="#keypiece" className="hero-link">keypiece.org</a>
                                            </Space>
                                        </Card>
                                    </Col>

                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction="vertical">
                                                <h3>Peek.blog</h3>
                                                <Paragraph>
                                                    Join Peek.blog, our vibrant online community for photo and story
                                                    enthusiasts. Share your experiences, connect with others, and
                                                    explore a world of creativity.
                                                </Paragraph>
                                                <a href="#peek-blog" className="hero-link">peek.blog</a>
                                            </Space>
                                        </Card>
                                    </Col>


                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction={"vertical"} justify={"space-between"} full>
                                                <Space direction="vertical">
                                                    <h3>ScrapsJS</h3>
                                                    <Paragraph>
                                                        ScrapsJS is an open-source inline JavaScript editing and running
                                                        library/framework, used by KeyPiece and our OakFrame internal
                                                        documentation pages.
                                                    </Paragraph>
                                                </Space>
                                                <Space>
                                                    <a href="#scrapsjs" className="hero-link">Learn More</a>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>

                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction={"vertical"} justify={"space-between"} full>

                                                <Space direction="vertical">
                                                    <h3>swuirm</h3>
                                                    <Paragraph>
                                                        swuirm is an open-source tool that enhances Docker, Docker
                                                        Compose,
                                                        Docker Swarm, and Terraform with SSH, build and deployment
                                                        configurations, environments, and more, all within a
                                                        user-friendly
                                                        UI.
                                                    </Paragraph>
                                                </Space>
                                                <Space>
                                                    <a href="#swuirm" className="hero-link">Discover More</a>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>

                                    <Col xs={12} md={4}>
                                        <Card full>
                                            <Space direction={"vertical"} justify={"space-between"} full>

                                                <Space direction="vertical">
                                                    <h3>DataBox</h3>
                                                    <Paragraph>
                                                        DataBox is an open-source Electron TSX desktop application for
                                                        data
                                                        engineers. It includes tools for image and video manipulation,
                                                        data
                                                        tagging and logging, audio-to-text conversion, AI LLM
                                                        integrations,
                                                        and cross-platform account and data integration.
                                                    </Paragraph>
                                                </Space>
                                                <Space> <a href="/products/#databox" className="hero-link">Find Out More</a>
                                                </Space>
                                            </Space>
                                        </Card>
                                    </Col>
                                </Row>
                            </Space>
                        </Col>

                    </Row>*/}
                </Content>
            </Page>
            <Footer/>
        </Layout>

    );
}

export default LandingPage;
