import {Col, DropDown, Link, Paragraph, Row, Space, Spacer, Title} from "../layout/Content";
import React from "react";
import {Trademark} from "./Trademark";
import {getPlatform, getVersion, getWrapper} from "../../lib/Wrapper";

export function Footer() {
    return (<footer><Row>
        <Col xs={24}>
            <div className={"limits"}>

                <Space justify={"space-between"} align={"center"}>
            <div>
            <Title><strong>the Peek<Trademark/> ©</strong></Title> <span>{new Date().getFullYear()}, The Peek Group Ltd.</span><br/>
            <small>version <strong>{getVersion()}</strong> {getPlatform()} <strong>{getWrapper()}</strong> </small>
            </div>
                <Space GapSm>
                    <Link href={"/info/privacy-policy"} className={"Small"}>Privacy Policy</Link> <Paragraph Muted>|</Paragraph>
                    <Link href={"/info/terms-of-service"} className={"Small"}>Terms of Service</Link> <Paragraph Muted>|</Paragraph>
                    <Link href={"/info/cookie-policy"} className={"Small"}>Cookie Policy</Link>
                </Space>
                </Space>
            </div>
        </Col>

    </Row></footer>);
}