/**
 * Converts a byte value into a human-readable file size string.
 * @param {number} bytes - The file size in bytes.
 * @param {number} decimals - The number of decimal places to format the output.
 * @returns {string} - The formatted file size with the appropriate unit.
 */
export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}


/**
 * Calculates the total file size of an array of media items.
 * @param {Array} mediaItems - Array of media items, each containing a filesize in bytes under item.original.filesize.
 * @returns {number} - The total file size of all media items combined, in bytes.
 */
export function getTotalFileSize(mediaItems: any[]) {
    let totalFileSize = 0;

    mediaItems.forEach(item => {
        if (item.original && item.original.filesize) {
            totalFileSize += item.original.filesize;
        }
    });

    return totalFileSize;
}

interface UploadOptions {
    headers?: Record<string, string>;
    credentials?: 'include' | 'same-origin' | 'omit';
    mode?: 'cors' | 'no-cors' | 'same-origin';
}


export interface UploadProgress {
    progress: number;
    totalBytes: number;
    sentBytes: number;
    bytesPerSecond: number;
    timeRemaining: number;
}

export const uploadWithProgress = (
    url: string,
    data: FormData,
    onProgress?: (progress: UploadProgress) => void,
    options: UploadOptions = {}
) => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);

        // Set headers if provided
        if (options.headers) {
            for (const [key, value] of Object.entries(options.headers)) {
                xhr.setRequestHeader(key, value);
            }
        }

        // Include credentials in the request if specified
        if (options.credentials) {
            xhr.withCredentials = options.credentials === 'include';
        }

        let startTime = Date.now(); // Record the start time for speed calculation

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const elapsed = (Date.now() - startTime) / 1000; // Time in seconds
                const bytesPerSecond = elapsed > 0 ? event.loaded / elapsed : 0;

                const bytesRemaining = event.total - event.loaded;
                const timeRemaining = bytesPerSecond > 0 ? bytesRemaining / bytesPerSecond : 0; // Time remaining in seconds

                const progressInfo: UploadProgress = {
                    progress: Math.round((event.loaded * 100) / event.total),
                    totalBytes: event.total,
                    sentBytes: event.loaded,
                    bytesPerSecond: Math.round(bytesPerSecond),
                    timeRemaining: Math.round(timeRemaining)
                };

                if (onProgress) {
                    onProgress(progressInfo);
                }
            }
        };

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                resolve(xhr.response);
            } else {
                reject(new Error(`Failed to upload: ${xhr.statusText}`));
            }
        };

        xhr.onerror = () => reject(new Error('Upload error'));

        xhr.send(data);
    });
};


export const uploadChunk = async (formData: FormData,    onChunkProgress?: (progress: UploadProgress) => void
): Promise<any> => {
    try {

        const response: any = await uploadWithProgress(`//${process.env.REACT_APP_DOMAIN_ASSET}/api/accumulation`, formData, onChunkProgress, {
            credentials: 'include',
            mode: 'cors'
        });

        const data = JSON.parse(response);
        console.log('Chunk uploaded', data);

        if (data && data.files && data.files.length) {
            const filename = data.files[0].uuid;
            //// updateContent(filename);
            //setContent(filename);
            //setFileData(data.files[0]);
            //message.success('Video uploaded successfully!');
            //setLoading(false);
        }

        return Promise.resolve(data);
    } catch (error) {
        console.error('Error uploading chunk', error);
        return Promise.reject(error);
    }
};