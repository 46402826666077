import React, { useState, useEffect, useCallback } from 'react';
import './PageTurner.scss';

type PageTurnerProps = {
    children: React.ReactNode[];
};

const PageTurner: React.FC<PageTurnerProps> = ({ children }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

    const totalPages = React.Children.count(children);
    const pagesToShow = isLandscape ? 2 : 1;

    const nextPage = useCallback(() => {
        setCurrentPage((prev) => (prev + pagesToShow) % totalPages);
    }, [pagesToShow, totalPages]);

    const prevPage = useCallback(() => {
        setCurrentPage((prev) => (prev - pagesToShow + totalPages) % totalPages);
    }, [pagesToShow, totalPages]);

    const handleResize = useCallback(() => {
        setIsLandscape(window.innerWidth > window.innerHeight);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [handleResize]);

    return (
        <div className="page-turner">
            <div className={`book ${isLandscape ? 'landscape' : 'portrait'}`}>
                {React.Children.map(children, (child, index) => (
                    <div
                        key={index}
                        className={`page ${index === currentPage || index === (currentPage + 1) % totalPages ? 'visible' : ''}`}
                    >
                        {child}
                    </div>
                ))}
            </div>
            <div className="controls">
                <button className="prev" onClick={prevPage}>Previous</button>
                <button className="next" onClick={nextPage}>Next</button>
            </div>
        </div>
    );
};

export default PageTurner;
