import React, { useReducer } from 'react';

type FieldValidation = {
    error: string | null;
    onChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
};

type ActionType = { type: 'SET_ERROR'; field: string; error: string | null } | { type: 'RESET_ERROR'; field: string };

type StateType = { [key: string]: FieldValidation };

const reducer = (state: StateType, action: ActionType): StateType => {
    switch (action.type) {
        case 'SET_ERROR':
            return {
                ...state,
                [action.field]: {
                    ...state[action.field],
                    error: action.error,
                },
            };
        case 'RESET_ERROR':
            return {
                ...state,
                [action.field]: {
                    ...state[action.field],
                    error: null,
                },
            };
        default:
            return state;
    }
};

const useFormMethodValidation = (fieldNames: string[]) => {
    const [state, dispatch] = useReducer(reducer, fieldNames.reduce((acc, fieldName) => {
        acc[fieldName] = { error: null, onChange: createOnChangeHandler(fieldName) };
        return acc;
    }, {} as StateType));

    function createOnChangeHandler(fieldName: string) {
        return (event?: React.ChangeEvent<HTMLInputElement>) => {
            dispatch({ type: 'RESET_ERROR', field: fieldName });
        };
    }

    const setError = (fieldName: string, error: string) => {
        dispatch({ type: 'SET_ERROR', field: fieldName, error });
    };

    const getError = (fieldName: string): string | null => {
        return state[fieldName]?.error || null;
    };

    const getHandlers = () => {
        return state;
    };

    return { setError, getError, getHandlers };
};

export default useFormMethodValidation;
