import React from 'react';
import './Badge.scss';

interface BadgeProps {
    color?: string;
    data?: string | number | React.ReactNode;
    circle?: boolean;
    children?: React.ReactNode;
}

const Badge: React.FC<BadgeProps> = ({ color = 'red', data, circle = false, children }) => {
    const badgeClass = circle ? 'badge-circle' : 'badge-flat';

    return (
        <div className="badge-container">
            {children}
            <div className={`badge ${badgeClass}`} style={{ backgroundColor: color }}>
                {data}
            </div>
        </div>
    );
};

export default Badge;
